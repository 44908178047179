import Utility from '~/services/Utility';

import { useBasicsStore } from '../services/base';
import { useUserAuthStore } from '../services/userAuth';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserAuthStore();
  const router = useRouter();
  const basicStore = useBasicsStore();

  if ($isClient() == true && $isEmpty(userStore.user)) {
    //await $alert("접근권한이 없습니다.");
    //return router.replace("/");
    // return navigateTo(`/login?redirectUrl=${to.fullPath}`);
    basicStore.setLoginModalState(true);
    // router.replace('/');
  }
});
